<template>
    <div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="昵称:">
					<el-input
						clearable
						@clear='clearContent'
						v-model="filters.name"
						placeholder="请输入关键字"
						@input="(e) => (filters.name = utils.validForbid(e))"
					>
					</el-input>
				</el-form-item>
				<el-form-item label="所属组织：" width>
					<el-cascader
						placeholder="请选择，支持搜索功能"
						style="width: 100%"
						v-model="OptionTreec"
						:options="options"
						:props="defaultProps"
						filterable
						change-on-select
						clearable
					>
					</el-cascader>
				</el-form-item>
				<el-form-item label="启用标识:">
					<el-select v-model="filters.selectc" clearable>
						<el-option
							v-for="item in optionsData"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="users"
			highlight-current-row
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%"
		>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<el-table-column type="index" width="80" align="center" :index='indexMethod'> </el-table-column>
			<el-table-column prop="name" label="昵称" width="150" align="center"></el-table-column>
			<el-table-column prop="loginAccount" label="登录名" width="150" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="phoneNumber" label="手机号" width="130" align="center"></el-table-column>
			<el-table-column prop="departmentName" label="所属组织" width="150" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="roleNames" label="角色" width="">
				<template slot-scope="scope">
					<el-tag v-for="item in scope.row.roleNames" :key="item.id">{{item}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="isEnable" label="启用标识" width="100" align="center">
				<template slot-scope="scope">
					<el-tag
						:type="scope.row.isEnable == true ? 'success' : 'danger'"
						disable-transitions
					>{{ scope.row.isEnable == true ? "启用" : "禁用" }}
					</el-tag>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pages.pageIndex"
			:page-sizes="pages.pageArr"
			:page-size="pages.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="pages.dataCount"
			>
			</el-pagination>
		</el-col>

		<!--新增-->
		<el-dialog
			title="新增"
			:visible.sync="addFormVisible"
			v-model="addFormVisible"
			:close-on-click-modal="false"
		>
			<el-form
				:model="addForm"
				label-width="130px"
				:rules="addFormRules"
				ref="addForm"
			>
			<el-form-item label="昵称:" prop="name">
				<el-input v-model="addForm.name" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="登录名:" prop="loginAccount">
				<el-input
					:value="addForm.loginAccount"
					@input="(e) => (addForm.loginAccount = validForbid(e))"
					auto-complete="off"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="短信验证手机号:" prop="phoneNumber">
				<el-input
					v-model="addForm.phoneNumber"
					onkeyup="value=value.replace(/[^\d]/g,'')"
					maxlength="11"
					auto-complete="off"
				>
				</el-input>
			</el-form-item>
			<el-form-item label="所属组织:" prop="optionTree">
				<el-cascader
					placeholder="请选择，支持搜索功能"
					style="width: 60%"
					v-model="addForm.optionTree"
					:options="options"
					:props="defaultProps"
					filterable
					change-on-select
					ref="addFormOptionRef"
				>
				</el-cascader>
			</el-form-item>
			<el-form-item label="管理组织:">
				<el-cascader
					placeholder="请选择，支持搜索功能"
					style="width: 60%"
					v-model="addForm.manageDepartmentListId"
					:options="options"
					:props="defaultProps1"
					filterable
					change-on-select
					clearable
				>
				</el-cascader>
			</el-form-item>
			<el-form-item label="角色:" prop="RoleIds">
				<el-select
					multiple
					v-model="addForm.roleIds"
					placeholder="请先选择所属组织"
				>
					<!-- <el-option  :key="0" :label="'未选择角色'" :value="0"></el-option> -->
					<el-option
						v-for="item in roles"
						:key="item.id"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
				<el-form-item label="是否启用:" prop="IsEnable">
					<el-select v-model="addForm.isEnable" placeholder="请选择是否启用">
						<el-option
							v-for="item in optionsData"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" v-model="addForm.remarks"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click.native="addFormVisible = false">取消</el-button>
			<el-button
				type="primary"
				@click.native="addSubmit"
				:loading="addLoading"
				>提交</el-button
			>
			</div>
		</el-dialog>
		<!--编辑-->
		<el-dialog
			title="编辑"
			:visible.sync="editFormVisible"
			v-model="editFormVisible"
			:close-on-click-modal="false"
		>
			<el-form
			:model="editForm"
			label-width="130px"
			:rules="editFormRules"
			ref="editForm"
			>
			<el-form-item label="昵称:" prop="name">
				<el-input v-model="editForm.name" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="登录名:" prop="loginAccount">
				<el-input
				:value="editForm.loginAccount"
				@input="(e) => (editForm.loginAccount = validForbid(e))"
				auto-complete="off"
				:disabled='true'
				></el-input>
			</el-form-item>
			<el-form-item label="短信验证手机号:" prop="phoneNumber">
				<el-input
				v-model="editForm.phoneNumber"
				maxlength="11"
				onkeyup="value=value.replace(/[^\d]/g,'')"
				auto-complete="off"
				></el-input>
			</el-form-item>
			<el-form-item label="所属组织:" prop="departmentName">
				<el-input
				v-model="editForm.departmentName"
				auto-complete="off"
				:disabled="true"
				></el-input>
			</el-form-item>
			<el-form-item label="管理组织:">
				<el-cascader
					placeholder="请选择，支持搜索功能"
					style="width: 60%"
					v-model="editForm.manageDepartmentListId"
					:options="options"
					:props="defaultProps1"
					filterable
					change-on-select
					clearable
				>
				</el-cascader>
			</el-form-item>
			<el-form-item label="角色:" prop="roleIds">
				<el-select
				multiple
				v-model="editForm.roleIds"
				placeholder="请选择角色"
				>
				<!-- <el-option  :key="0" :label="'未选择角色'" :value="0"></el-option> -->
				<el-option
					v-for="item in roles"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否启用:" prop="isEnable">
				<el-select v-model="editForm.isEnable" placeholder="请选择是否启用">
				<el-option
					v-for="item in optionsData"
					:key="item.value"
					:label="item.name"
					:value="item.value"
				>
				</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注:">
				<el-input type="textarea" v-model="editForm.remarks"></el-input>
			</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click.native="editFormVisible = false">取消</el-button>
			<el-button
				type="primary"
				@click.native="editSubmit"
				:loading="editLoading"
				>提交</el-button
			>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
	getUserListPage,
	updateUserInfo,
	addUserInfo,
	deleteUserInfo,
	getOrganizationTree,
	getRoleInfosByOrganizationInfoID,
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
export default {
	components: { Toolbar },
	data() {
		//验证手机号
		let isPhoneNumberNum = (rule, value, callback) => {
			if (!validPhone(value)) {
				return callback(new Error("请输入正确的手机号"));
			} else {
				callback();
			}
		};
		return {
			utils: '',
			users: [],
			roles: [],
			listLoading: false,
			currentRow: null,
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			//顶部筛选条件
			filters: {
				name: "",
				selectc: true,
			},
			//关于分页的obj
			pages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			//新增界面是否显示
			addFormVisible: false,
			addLoading: false,
			addFormRules: {
				loginAccount: [
					{ required: true, message: "请输入登录名", trigger: "blur" },
				],
				name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
				phoneNumber: [
					{ required: true, message: "不能为空", trigger: "blur" },
					{ validator: isPhoneNumberNum },
				],
				optionTree: [
					{ required: true, message: "请选择所属组织", trigger: "blur" },
				],
			},
			//dialog数据
			addForm: {
				name: "",
				loginAccount: "",
				roleIds: [],
				organizationInfoID: "",
				manageDepartmentListId: [],
				id: "",
				isEnable: true,
				phoneNumber: "",
			},
			//编辑界面是否显示
			editFormVisible: false,
			editLoading: false,
			editFormRules: {
				loginAccount: [
					{ required: true, message: "请输入登录名", trigger: "blur" },
				],
				name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
				phoneNumber: [
					{ required: true, message: "不能为空", trigger: "blur" },
					{ validator: isPhoneNumberNum },
				],
			},
			//dialog数据
			editForm: {
				name: "",
				loginAccount: "",
				roleIds: [],
				organizationInfoID: "",
				manageDepartmentListId: [],
				id: "",
				isEnable: "",
				phoneNumber: "",
			},
			//是否启用状态
			optionsData: [{
				value: true,
				name: "启用",
			},{
				value: false,
				name: "禁用",
			}],
			// 组织树数据
			defaultProps: {
				children: "children",
				label: "name",
				value: "id", //可要可不要
			},
			defaultProps1: {
				children: "children",
				label: "name",
				value: "id", //可要可不要
				multiple: true,
			},

			OptionTreec: [],
			options: [],
		};
	},
	methods: {
		// 清空筛选框内容，重新获取数据
		clearContent(){
			this.getData()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
		//显示新增界面
		handleAdd() {
			this.addForm = {
				name: "",
				loginAccount: "",
				isEnable: true,
				organizationInfoID: 0,
				manageDepartmentListId: [],
				roleIds: [],
				id: JSON.parse(localStorage.getItem("user")).ID,
				isDeleted: true,
			};
			this.getRolesFun();
			this.addFormVisible = true;
		},
		//显示编辑界面
		handleEdit() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要编辑的一行数据！",
					type: "error",
				});
				return;
			}
			this.editForm = Object.assign({}, row);
			this.getRolesFun();
			this.editFormVisible = true;
		},
		//新增
		addSubmit: function () {
			this.$refs.addForm.validate((valid) => {
				if (valid) {
					this.$confirm("确认提交吗？", "提示", {}).then(() => {
						this.addLoading = true;
						let params = Object.assign({}, this.addForm);
						// params.OrganizationInfoID = this.addForm.OptionTree.pop()
						params.departmentId = this.addForm.optionTree[
							this.addForm.optionTree.length - 1
						];
						var newArr = []
						// this.addForm.manageDepartmentId.forEach(item => {
						// 	item.forEach(i => {
						// 		newArr.push(i)
						// 	})
						// });
						// var newFilterArr = newArr.filter(function(item,index) {
						// 	return newArr.indexOf(item) == index;
						// })
						// if(newFilterArr.length > 0) {
						// 	params.manageDepartmentId = newFilterArr
						// }
						addUserInfo(params).then((res) => {
							if (util.isEmt.format(res)) {
								this.addLoading = false;
								return;
							}

							if (res.data.success) {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: "success",
								});
								this.addFormVisible = false;
								this.$refs["addForm"].resetFields();
								this.getData();
							} else {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: "error",
								});
							}
						});
					});
				}
			});
		},
		//编辑
		editSubmit: function () {
			this.$refs.editForm.validate((valid) => {
				if (valid) {
					this.$confirm("确认提交吗？", "提示", {}).then(() => {
						this.editLoading = true;
						let params = Object.assign({}, this.editForm);
						var newArr = []
						// this.editForm.manageDepartmentId.forEach(item => {
						// 	item.forEach(i => {
						// 		newArr.push(i)
						// 	})
						// });
						// var newFilterArr = newArr.filter(function(item,index) {
						// 	return newArr.indexOf(item) == index;
						// })
						// if(newFilterArr.length > 0) {
						// 	params.manageDepartmentId = newFilterArr
						// }
						updateUserInfo(params).then((res) => {
							if (util.isEmt.format(res)) {
								this.editLoading = false;
								return;
							}
							if (res.data.success) {
								this.editLoading = false;
								this.$message({
									message: res.data.message,
									type: "success",
								});
								this.editFormVisible = false;
								this.getData();
							} else {
								this.editLoading = false;
								this.$message({
									message: res.data.message,
									type: "error",
								});
							}
						});
					});
				}
			});
		},
		//删除
		handleDel() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要删除的一行数据！",
					type: "error",
				});
				return;
			}
			this.$confirm("确认删除该用户吗?", "提示", {
				type: "warning",
			})
			.then(() => {
				this.listLoading = true;
				let params = { id: row.id };
				deleteUserInfo(params).then((res) => {
					if (util.isEmt.format(res)) {
						this.listLoading = false;
						return;
					}
					if (res.data.success) {
						this.listLoading = false;
						this.$message({
							message: res.data.message,
							type: "success",
						});
						this.userFormVisible = false;
						this.getData();
					} else {
						this.listLoading = false;
						this.$message({
							message: res.data.message,
							type: "error",
						});
					}
				});
			})
			.catch(() => {});
		},
		// 查询
		getUsers(){
			this.pages.pageIndex = 1
			this.getData()
		},
		//获取用户列表
		getData() {
			this.listLoading = true;
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				userName: this.filters.name,
				isEnable: this.filters.selectc,
			};  
			if (this.OptionTreec.length > 0) {
				params.departmentInfoId = this.OptionTreec[
					this.OptionTreec.length - 1
				];
			}
			getUserListPage(params).then((res) => {
				this.users = res.data.response.data;
				this.listLoading = false;

				this.pages.dataCount = res.data.response.dataCount;
				this.pages.pageIndex = res.data.response.pageIndex;
				if (res.data.response.pageSize > 0) {
					this.pages.pageSize = res.data.response.pageSize;
				}
			});
		},
		// 获取树结构数据
		getOrganizations() {
			var _this = this;
			var ID = JSON.parse(localStorage.getItem("user")).ID;
			var params = {
				parentId: 0,
			};
			getOrganizationTree(params).then((res) => {
				var data = res.data;
				var resData = [data.response];
				if (data.success) {
					if (data.response.name == "根节点") {
						_this.options = data.response.children;
					} else {
						_this.options = resData;
					}
				}
			});
		},
		//根据组织结构获取角色列表
		// addFormOptionChange() {
		// 	if (this.$refs["addFormOptionRef"].checkedValue) {
		// 		if (this.$refs["addFormOptionRef"].checkedValue.length > 0) {
		// 			this.getRolesFun(
		// 				this.$refs["addFormOptionRef"].checkedValue[
		// 					this.$refs["addFormOptionRef"].checkedValue.length - 1
		// 				]
		// 			);
		// 		}
		// 	}
		// },
		getRolesFun() {
			var params = {};
			getRoleInfosByOrganizationInfoID(params).then((res) => {
				this.roles = res.data.response;
			});
		},

		callFunction(item) {
			this[item.func].apply(this, item);
		},
		// 分页
		handleCurrentChange(val) {
			// if(this.filters.name != '' || this.filters.selectc != false){
			//   this.pages.pageIndex = 1
			// }else{
				this.pages.pageIndex = val;
			// }      
			this.getData();
		},
		handleSizeChange(val) {
			this.pages.pageSize = val;     
			this.getData();
		},
		validForbid(value, number = 2550) {
			value = value
			.replace(
				/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
				""
			)
			.replace(/\s/g, "");
			if (value.length >= number) {
				this.$message({
					type: "warning",
					message: `输入内容不能超过${number}个字符`,
				});
			}
			return value;
		},
		// 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
	},
	mounted() {
		this.utils = util
		this.getData();
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getOrganizations();
	},
};
</script>
<style lang="stylus" scoped>
.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
  	margin-top: 15px;
}
</style>
